
import { computed, defineComponent, ref } from "vue";

import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "TopCardOccurencesPercentage",
  components: {},
  props: {
    bgAndTextColor: String,
    tittle: String,
    tittleIcon: String,
    info: Object,
    value: Number,

    gradientStyle: String,

    widgetClasses: String,

    chartHeight: String,
    btnColor: String,
  },
  computed: {},

  setup(props) {


    const baseColor = "black";
    const lightColor = "#f3f2f1";
    const labelColor = "black";

    const chartOptions = {
      chart: {
        fontFamily: "inherit",
        height: "200",
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "65%",
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              fontWeight: "700",
            },
            value: {
              color: labelColor,
              fontSize: "30px",
              fontWeight: "700",
              offsetY: 12,
              show: true,
              formatter: function (val) {
                return val + "%";
              },
            },
          },
          track: {
            background: lightColor,
            strokeWidth: "100%",
          },
        },
      },
      colors: [baseColor],
      stroke: {
        lineCap: "round",
      },
      labels: ["Progress"],
    };

    return {
      chartOptions,
    };
  },
});
