
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "TopCardOccurencesInfo",
  components: {},
  props: {
    bgAndTextColor: String,
    tittle: String,
    tittleIcon: String,
    info: Object,
    value: String,

    gradientStyle: String,
  },
  computed: {
    imgUrl: function () {
      return "media/" + this.info?.background;
    },
    imgBGStyle: function () {
      return (
        "background-image: url('media/" +
        this.info?.background +
        "'); bottom:0px; top:0px, right:0px; left:0px; position: absolute; width: 100%;" +
        "height: " +
        this.info?.height +
        "; 100%; opacity:0.1; background-repeat: no-repeat; background-size:contain; background-position-x: " +
        this.info?.backgroundPositionX +
        "; background-position-y: " +
        this.info?.backgroundPositionY +
        ";"
      );
    },
  },
});
