import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card card-xxl-stretch mb-5 mb-xl-8" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "pe-0" }
const _hoisted_4 = { class: "fs-1 m-2 svg-icon svg-icon-1 svg-icon-gray-100 h-100 d-flex align-items-center" }
const _hoisted_5 = { class: "text-white fw-bold fs-8 m-2" }
const _hoisted_6 = {
  class: "card-body d-flex h-100 justify-content-center align-items-center",
  style: {"padding":"0 !important","margin":"0 !important","min-height":"150px","max-height":"150px"}
}
const _hoisted_7 = { class: "flex-grow-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      style: _normalizeStyle([_ctx.gradientStyle, {"min-height":"50px"}]),
      class: "rounded-top"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, {
              src: 'media/' + _ctx.tittleIcon
            }, null, 8, ["src"]),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.tittle), 1)
          ])
        ])
      ])
    ], 4),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_apexchart, {
          options: _ctx.chartOptions,
          series: [_ctx.value],
          height: _ctx.chartHeight,
          type: "radialBar"
        }, null, 8, ["options", "series", "height"])
      ])
    ])
  ]))
}