<template>
  <div :class="widgetClasses" class="card" style="border-radius: 1.5rem">
    <!--begin::Header-->

    <CustomTableTopFilter
      :filterStatus="filterStatus"
      :pageProp="pageProp"
      :tableName="tableName"
      :pages="pages"
      @paginatorHandler="paginatorHandler"
      @updateStatus="updateStatus"
      @queryForOccurences="queryForOccurences"
    ></CustomTableTopFilter>

    <hr/>
    <!--begin::Table of occurences-->
    <div class="card-body mb-5 py-3">
      <div class="table-responsive fixTableHead">
        <!--begin::Table-->
        <table class="table table-row-gray-500 gy-5 gs-5 mb-0">
          <!--begin::Table head-->
          <thead id="">
            <tr class="fw-bolder text-muted">
              <th class="text-dark fw-bolder">ID</th>
              <th class="text-dark fw-bolder">Documento Associado</th>
              <th class="text-dark fw-bolder">Data de criação</th>              
              <th class="text-dark fw-bolder">Quantidade</th>
              <th class="text-dark fw-bolder">Estado</th>
              <th></th>
            </tr>
          </thead>
          <!--end::Table head-->

          <!--begin::Table body-->
          <tbody>
            <template v-for="(item, index) in occurences" :key="index">
              <tr>
                <td class="text-dark fs-6">#{{ item.numDossier }}</td>
                <td class="text-dark fs-6">{{ item.refCl }}</td>
                <td class="fs-6">
                  {{ getFormatedDate(item.dataDossier) }}
                </td>          
                <td class="fs-6">
                  {{ item["dossierLinha"].length }}
                </td>
                <td class="fs-6"  >
                  <p :style="occStatusColors(item.fechado)" id="occStatus">{{ item.fechado == true ? 'Fechada' : 'Em análise' }}</p>
                </td>
                <td>
                  <button
                    @click="showRowDetail(item.numDossier)"
                    class="
                      btn btn-icon btn-bg-light btn-active-color-danger btn-sm
                    "
                    style="background-color: white"
                  >
                    <span class="svg-icon svg-icon-3">
                      <inline-svg
                        src="media/icons/duotune/general/gen053.svg"
                      />
                    </span>
                  </button>
                </td>
              </tr>
            </template>
          </tbody>
          <!--end::Table body-->
        </table>
        <!--end::Table-->
      </div>
      <!--end::Table of occurences-->
    </div>
  </div>
</template>



<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useRouter } from "vue-router";
import CustomTableTopFilter from "@/components/customTables/CustomTableTopFilter.vue";

const router = useRouter();

export default {
  name: "OccurenceTable",
  components: {
    CustomTableTopFilter,
  },
  data() {
    return {
      page : 0
    }
  },
  props: {
    widgetClasses: String,
    tableName: String,
    occurences: Array,
    pageProp: Number,
    pages: Number,
    filterStatus: Array,
  },
  emit: ["paginatorHandler", "updateStatus", "queryForOccurences"],
  watch: {
    pageProp() {
      this.page = this.pageProp;
    },
  },
  computed: {
    
  },
  methods: {
    getFormatedDate(date) {
      return new Date(date).toLocaleDateString('pt-pt').replaceAll('/', '.')
    },

    showRowDetail(incNmr) {
      this.$router.push("/resolucao/" + incNmr);
    },
    paginatorHandler(page) {
      this.$emit("paginatorHandler", page);
    },
    updateStatus(status) {
      this.$emit("updateStatus", status);
    },
    queryForOccurences(searchInput) {
      this.$emit("queryForOccurences", searchInput);
    },
    occStatusColors(status) {
      switch (status) {
        case true:
          return "background-color: #d9eecc; color: #3d9a01 !important;";
        case false:
          return "background-color: #fbecd3; color: #eaa224 !important;";
        default:
          return "background-color: #fbecd3; color: #eaa224 !important";
      }
    },
  },


};
</script>

<style scoped>
  #occStatus {
  border-radius: 15px;

  text-align: center;
}


th {
  
  text-align: -webkit-center;
}

td {
  text-align: -webkit-center;
  border-bottom: 2px solid white;
}
tbody tr:nth-child(even) {
  background-color: #cfcccc;
  
  
}

tbody tr:nth-child(odd) {
  background-color: #f3f2f1;
}

#table-head {
  background-color: #791b1d;
}
</style>